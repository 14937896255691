import precizniZemedelstvi from './img/precizni-zemedelstvi.png'
import iagrooldris from './img/iagrooldris.png'
const more = "Zobrazit více"

const allProjects = [
    {
        id: 1,
        image: precizniZemedelstvi,
        title: "Precizní zemědělství",
        desc: "Vyvýjím aplikaci, která pomůže zemědělcům získat co největší výnosy s nejmenšími výdaji za hnojiva.",
        link: "",
        more: "",
    },
    {
        id: 2,
        image: iagrooldris,
        title: "I. Agro Oldřiš",
        desc: "Statická webová stránka pro I. Agro Oldřiš.",
        link: "https://agro-oldris.cz",
        more: more,
    },
]

export default allProjects