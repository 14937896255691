import './Err404.css'

const Err404 = () => {
  return (
    <div className="err404">
      <img src="https://i.kym-cdn.com/photos/images/original/000/236/756/b33.jpg"></img>
    </div>
  )
}

export default Err404