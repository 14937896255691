import {NavLink} from 'react-router-dom'

import './Navbar.css'

const Navbar = () => {
  return <>
     <nav>
        <NavLink to="/">Domů</NavLink>
        <NavLink to="/socials">Kontakty</NavLink>
     </nav>
  </>
}

export default Navbar