import {Link} from 'react-router-dom'

import './Footer.css'

const Footer = () => {
  return <>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"></link>
    <footer>
        <h2>©Pincek.eu 2024</h2>
        <h2>Vytvořeno s <i class="fas fa-beer"></i> od <Link to="https://pincek.eu" className='link'>Jan Koutný</Link></h2>
    </footer>
  </>
}

export default Footer