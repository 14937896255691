import './Hero.css'

const Hero = () => {
  return <section>
    <h1>Jan Koutný</h1>
    <p>Vývojář webových aplikací</p>
    <div class="mouse_scroll">
        <div class="mouse">
            <div class="wheel"></div>
        </div>
        <div>
            <span class="m_scroll_arrows unu"></span>
            <span class="m_scroll_arrows doi"></span>
            <span class="m_scroll_arrows trei"></span>
        </div>
    </div>
  </section>
}

export default Hero