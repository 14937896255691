import Hero from '../components/Hero'
import Projects from '../components/Projects'

const Home = () => {
  return <>
    <Hero/>
    <Projects/>
  </>
}

export default Home