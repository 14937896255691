import './Socials.css'

const Socials = () => {
return <div className='socials'>
  <h2>Kontakty</h2>
  <div>
    <a href="https://github.com/koutnyj" target="_blank">Github</a>
    <a href="https://www.linkedin.com/in/jan-koutn%C3%BD/" target="_blank">Linkedin</a>
    <a href="https://www.instagram.com/pincek_z_vesnice/" target="_blank">Instagram</a>
    <a href="mailto:kontakt@pincek.eu">Email</a>
  </div>
</div>
}

export default Socials