import {BrowserRouter, Routes, Route} from 'react-router-dom'

import Home from './pages/Home'
import Socials from './pages/Socials'
import Err404 from './pages/Err404'
import SharedLayout from './pages/SharedLayout'
import './pages/SharedLayout.css'

const App = () => {
  return <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <SharedLayout/> }>
          <Route index element={ <Home/> }/>
          <Route path='/socials' element={ <Socials/> }/>
          <Route path='*' element={ <Err404/> }/>
        </Route>
      </Routes>
    </BrowserRouter>
  </>
}

export default App