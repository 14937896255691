import './Projects.css'

import data from '../data'

const Projects = () => {
  return <div>
    <div className="projects">
      {
        data.map((oneProject) => {
          const {id, image, title, desc, link, more} = oneProject

          return <div className='one-project' key={id}>
            {link !== "" ? <a href={link} target='_blank' rel="noreferrer"><img src={image} alt=''></img></a> : <img src={image} alt=''></img>}
            <h2>{title}</h2>
            <p>{desc}</p>
            <a href={link} target='_blank' rel="noreferrer">{more}</a>
          </div>
        })
      }
    </div>
  </div>
}

export default Projects